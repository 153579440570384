// src/Components/Auth/Login.js
import React, { useEffect, useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';import { useNavigate, useLocation, Link } from 'react-router-dom';
import { app } from './firebase'; // Adjust this import path as needed
import { Button, Typography, Paper, TextField, styled, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  margin: 'auto',
  marginTop: theme.spacing(8),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
}));



const PRODUCTION_URL = 'https://your-production-app.com';



function Login() {
  const navigate = useNavigate();
  const auth = getAuth(app);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const location = useLocation();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');


  useEffect(()=>{
    document.title="login"

  },[])
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setResetMessage(`Error: ${error.message}`);
    }
  };


  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result= await signInWithPopup(auth, provider);
      const user = result.user;
      sessionStorage.setItem('user', JSON.stringify({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName
      }));
      navigate('/');  // Redirect to home page after successful login
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError(error.message);
    }
  };

  const handleEmailPasswordAction = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }
      navigate('/');  // Redirect to home page after successful login/registration
    } catch (error) {
      console.error(`Error ${isLogin ? 'signing in' : 'registering'} with email/password`, error);
      setError(error.message);
    }
  };

  return (
    <StyledPaper elevation={3}>
      {/* <Typography variant="h4" component="h1" gutterBottom>
        {isLogin ? 'Login' : 'Register'}
      </Typography> */}
      {/* <FormControlLabel
        control={<Switch checked={!isLogin} onChange={() => setIsLogin(!isLogin)} />}
        label={isLogin ? "Need to register?" : "Already have an account?"}
      /> */}
      <form onSubmit={handleEmailPasswordAction} style={{ width: '100%' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
        >
          {isLogin ? 'Sign In' : 'Register'}
        </StyledButton>
      </form>
      {/* {isLogin && (
        <Typography variant="body2" style={{ marginTop: '1rem', cursor: 'pointer', color: 'blue' }} onClick={() => setOpenResetDialog(true)}>
          Forgot Password?
        </Typography>
      )} */}
      {/* <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Or
      </Typography> */}
      {/* <StyledButton
        fullWidth
        variant="contained"
        onClick={signInWithGoogle}
        startIcon={<img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" width="18" height="18" />}
      >
        Sign In with Google
      </StyledButton> */}
      {error && (
        <Typography color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
   <Dialog open={openResetDialog} onClose={() => setOpenResetDialog(false)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email address and we'll send you a link to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="resetEmail"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          {resetMessage && (
            <Typography color={resetMessage.includes('Error') ? 'error' : 'primary'}>
              {resetMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResetDialog(false)}>Cancel</Button>
          <Button onClick={handleResetPassword}>Send Reset Email</Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
}

export default Login;
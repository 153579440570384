import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Box,
  Link,
  CircularProgress
} from "@mui/material";
import UpdateCompanyModal from "../Insertion/UpdateCompanyModal";
import { ApplicationURL } from "../../../src/apiEndpoints";
import {  Delete as DeleteIcon } from '@mui/icons-material';

function AllEmployers() {
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [updatedCompany, setUpdatedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSave = async (updatedData) => {
    try {
      const response = await fetch(`${ApplicationURL['public-url']}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const updatedCompanies = companies.map((company) => 
          company.id === updatedData.id ? updatedData : company
        );
        setCompanies(updatedCompanies);
        setIsModalOpen(false);
      } else {
        console.error("Error updating company");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUpdatedCompany(null);
  };

  const handleUpdate = (companyId) => {
    const companyToUpdate = companies.find((company) => company.id === companyId);
    const sanitizedCompany = sanitizeCompanyData(companyToUpdate);
    setUpdatedCompany(sanitizedCompany);
    setIsModalOpen(true);
  };

  const sanitizeCompanyData = (data) => {
    const defaultValues = {
      id: "", name: "", size: "", primaryIndustry: "", secondary: "",
      state: "", country: "", careersPage: "", companyLogoUrl: "",
    };
    return Object.keys(defaultValues).reduce((acc, key) => {
      acc[key] = data[key] == null ? defaultValues[key] : data[key];
      return acc;
    }, {});
  };

  const handleDelete = async (companyId) => {
    try {
      const response = await fetch(`${ApplicationURL['public-url']}${companyId}/`, {
        method: "DELETE",
      });
      if (response.ok) {
        setCompanies(companies.filter((company) => company.id !== companyId));
      } else {
        console.error("Error deleting company");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {

    document.title="Home"
    fetch(`${ApplicationURL['public-url']}available-categories/`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(["All", ...data]);
        if(data.length>1){
          setSelectedCategory(data[0]);
        }

      })
      .catch((error) => console.error("Error fetching categories: ", error));
  },[]);

  useEffect(() => {
    if (selectedCategory!=="") {
      setIsLoading(true);
      const url = selectedCategory === "All"
        ? `${ApplicationURL['public-url']}all/`
        : `${ApplicationURL['public-url']}${selectedCategory}/`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setCompanies(data.companies);
          setTotalResults(data.count);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching companies: ", error);
          setIsLoading(false);
        });
    }
  }, [selectedCategory]);



  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Company List
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel id="category-select-label">Choose a Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              label="Choose a Category"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="subtitle1">
            Results: {totalResults}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="company table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Primary Industry</TableCell>
                <TableCell>Secondary</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Careers Page</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell>{company.id}</TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.size}</TableCell>
                  <TableCell>{company.primaryIndustry}</TableCell>
                  <TableCell>{company.secondary}</TableCell>
                  <TableCell> {company.state && company.state.split(',').length > 1
        ? company.state.split(',').slice(0, 3).join(', ') + '...'
        : company.state}</TableCell>
                  <TableCell>{company.country}</TableCell>
                  <TableCell>
                    {company.careersPage ? (
                      <Link href={company.careersPage} target="_blank" rel="noopener noreferrer">
                        Career Page
                      </Link>
                    ) : (
                      "Not available"
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                  
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleUpdate(company.id)}
                        size="small"
                      >
                        Update
                      </Button>


                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(company.id)}
                        size="small"
                        sx={{marginLeft:3}}
                      >
                    <DeleteIcon />
                      </Button>

                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isModalOpen && (
        <UpdateCompanyModal
          company={updatedCompany}
          isOpen={isModalOpen}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Container>
  );
}

export default AllEmployers;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCV8KKZtVwqPfrmmq84Bo55E_0o5bbCXgw",
  authDomain: "admin-glancejobsopt.firebaseapp.com",
  projectId: "admin-glancejobsopt",
  storageBucket: "admin-glancejobsopt.appspot.com",
  messagingSenderId: "638924434283",
  appId: "1:638924434283:web:51cfe3c1a456de893dba2e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export { app };
import React, { useState, useEffect } from "react";
import { Tabs, Tab, styled, IconButton, Menu, MenuItem, AppBar, Toolbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';
import { app } from "../firebase";
import MenuIcon from '@mui/icons-material/Menu';

const StyledTab = styled(Tab)({
  color: 'black',
  '&:hover': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  '&.Mui-selected': {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  },
  transition: 'box-shadow 0.3s ease-in-out',
});

function NavigationTabs({ user }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname);
  const auth = getAuth(app);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const publicMenuItems = [];

  const protectedMenuItems = [
    // { label: "US TOP Employers", path: "/TOPEmployers" },
    { label: "All", path: "/All" },
    { label: "New Company", path: "/Create" },
  ];
  
  const authMenuItem = user 
    ? { label: "Sign Out", value: "/signout", onClick: handleSignOut }
    : { label: "Login", value: "/login" };

  const mainMenuItems = [...publicMenuItems, ...(user ? protectedMenuItems : [])];
  const allMenuItems = [...mainMenuItems, authMenuItem];

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          textColor="inherit" 
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ flexGrow: 1 }}
        >
          {mainMenuItems.map((item) => (
            <StyledTab
              key={item.path}
              label={item.label}
              value={item.path}
              component={Link}
              to={item.path}
            />
          ))}
        </Tabs>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {allMenuItems.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                } else {
                  navigate(item.value);
                }
                handleMenuClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationTabs;

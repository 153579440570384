import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import { ApplicationURL } from "../../../src/apiEndpoints";

function UpdateCompanyModal({ company, isOpen, onSave, onCancel }) {
  const [formData, setFormData] = useState(company);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(`${ApplicationURL['public-url']}available-categories/`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => console.error("Error fetching categories: ", error));
  }, []);

  useEffect(() => {
    setFormData(company);
  }, [company]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!formData.name || !formData.size || !formData.primaryIndustry) {
      alert("Please enter required fields *");
      return;
    }
    onSave(formData);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Update Company</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="size"
            label="Size"
            name="size"
            value={formData.size}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="state"
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="primary-industry-label">Primary Industry</InputLabel>
            <Select
              labelId="primary-industry-label"
              id="primaryIndustry"
              name="primaryIndustry"
              value={formData.primaryIndustry}
              onChange={handleChange}
              label="Primary Industry"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            id="secondary"
            label="Secondary Industry"
            name="secondary"
            value={formData.secondary}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="country"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="careersPage"
            label="Careers Page"
            name="careersPage"
            value={formData.careersPage}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="companyLogoUrl"
            label="Logo URL"
            name="companyLogoUrl"
            value={formData.companyLogoUrl}
            onChange={handleChange}
            placeholder="Upload an image for URL"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateCompanyModal;
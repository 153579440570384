import React, { useState, useEffect } from "react";
import { HashRouter as BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppBar, Toolbar, Box, styled } from "@mui/material";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebase';
import TopEmployers from "./Components/Listings/TopEmployers";
import AllEmployers from "./Components/LargeEmployers/AllEmployers";
import DatabaseUpdateHome from "./Components/Insertion/DatabaseUpdateHome";
import CreateCompany from "./Components/Insertion/CreateCompany";
import Login from "./Login";
import NavigationTabs from "./Navigation/NavigationTabs";
import CompanyRequestForm from "./Components/Insertion/CompanyRequestForm";


const WhiteAppBar = styled(AppBar)({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  borderBottom: '1px solid #e0e0e0',
});

const auth = getAuth(app);

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    document.title='login'

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <Box sx={{ flexGrow: 1 }}>
        <WhiteAppBar position="static">
          <Toolbar>
            <NavigationTabs user={user} />
          </Toolbar>
        </WhiteAppBar>

        <Routes>
          <Route path="/" element={<ProtectedRoute><AllEmployers /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/All" element={<ProtectedRoute><AllEmployers /></ProtectedRoute>} />
          <Route path="/TOPEmployers" element={<ProtectedRoute><TopEmployers /></ProtectedRoute>} />
          <Route path="/DatabaseUpdate" element={<ProtectedRoute><DatabaseUpdateHome /></ProtectedRoute>} />
          <Route path="/Create" element={<ProtectedRoute><CreateCompany /></ProtectedRoute>} />
          <Route path="/company-requests" element={<ProtectedRoute><CompanyRequestForm /></ProtectedRoute>} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}

export default App;

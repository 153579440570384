import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ApplicationURL } from "../../../src/apiEndpoints";

const CompanyRequestForm = () => {
  const [user, setUser] = useState(null);
  const [companyRequest, setCompanyRequest] = useState({
    name: '',
    size: '',
    primaryIndustry: '',
    secondary: '',
    state: '',
    country: '',
    careersPage: '',
    userId: ''
  });
  const [editingId, setEditingId] = useState(null);
  const [userRequests, setUserRequests] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Retrieve user information from session storage
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setCompanyRequest(prev => ({ ...prev, userId: parsedUser.uid }));
      fetchUserRequests(parsedUser.uid);
    }

    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const response = await axios.get(`${ApplicationURL['public-url']}available-categories/`);
      setIndustries(response.data);
    } catch (error) {
      console.error('Error fetching industries', error);
    }
  };

  const fetchUserRequests = async (userId) => {
    try {
      const response = await axios.get(`${ApplicationURL['public-url']}companyrequest/?userId=${userId}`);
      setUserRequests(response.data);
    } catch (error) {
      console.error('Error fetching user requests', error);
    }
  };

  const handleChange = (e) => {
    setCompanyRequest({ ...companyRequest, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await axios.put(`${ApplicationURL['public-url']}companyrequest/${editingId}/`, companyRequest);
        setSnackbarMessage("Company request updated successfully");
      } else {
        await axios.post(`${ApplicationURL['public-url']}newCompanyRequest/`, companyRequest);
        setSnackbarMessage("Company request created successfully");
      }
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      resetForm();
      fetchUserRequests(user.uid);
    } catch (error) {
      console.error('Error submitting company request', error);
      setSnackbarMessage("Error submitting company request");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleEdit = (request) => {
    setCompanyRequest(request);
    setEditingId(request.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${ApplicationURL['public-url']}companyrequest/${id}/`);
      setSnackbarMessage("Company request deleted successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      fetchUserRequests(user.uid);
    } catch (error) {
      console.error('Error deleting company request', error);
      setSnackbarMessage("Error deleting company request");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const resetForm = () => {
    setCompanyRequest({
      name: '',
      size: '',
      primaryIndustry: '',
      secondary: '',
      state: '',
      country: '',
      careersPage: '',
      userId: user ? user.uid : ''
    });
    setEditingId(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (!user) {
    return <Typography>Please log in to submit company requests.</Typography>;
  }
  
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          {editingId ? 'Edit Company Request' : 'Create New Company Request'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={companyRequest.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="size"
            label="Size"
            value={companyRequest.size}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="primary-industry-label">Primary Industry</InputLabel>
            <Select
              labelId="primary-industry-label"
              name="primaryIndustry"
              value={companyRequest.primaryIndustry}
              onChange={handleChange}
              label="Primary Industry"
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="secondary"
            label="Secondary"
            value={companyRequest.secondary}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="state"
            label="State"
            value={companyRequest.state}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="country"
            label="Country"
            value={companyRequest.country}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="careersPage"
            label="Careers Page URL"
            value={companyRequest.careersPage}
            onChange={handleChange}
            placeholder="www.careerspageurl.com"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {editingId ? 'Update Request' : 'Submit Request'}
          </Button>
          {editingId && (
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ mt: 1 }}
              onClick={resetForm}
            >
              Cancel Edit
            </Button>
          )}
        </form>

        <Typography variant="h5" component="h3" gutterBottom sx={{ mt: 4 }}>
          Your Company Requests
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Primary Industry</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.name}</TableCell>
                  <TableCell>{request.primaryIndustry}</TableCell>
                  <TableCell>{request.size}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(request)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(request.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default CompanyRequestForm;
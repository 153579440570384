import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { ApplicationURL } from "../../../src/apiEndpoints";

function CreateCompany() {
  const [company, setCompany] = useState({
    name: '',
    size: '',
    primaryIndustry: '',
    secondary: '',
    state: '',
    country: '',
    careersPage: ''
  });
  const [response, setResponse] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const requiredFields = ["name", "primaryIndustry", "size"];

  useEffect(() => {
    // Fetch industries from your API
    axios.get(`${ApplicationURL['public-url']}available-categories/`)
      .then(response => {
        setIndustries(response.data);
      })
      .catch(error => {
        console.error('Error fetching industries', error);
      });
  }, []);

  const handleChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!company.size || !company.primaryIndustry || !company.name) {
      setSnackbarMessage("Please enter all required fields");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    try {
      const res = await axios.post('https://api.test.opt.glancejobs.com/company/', company);
      setResponse(res.data);
      setCompany({
        name: '',
        size: '',
        primaryIndustry: '',
        secondary: '',
        state: '',
        country: '',
        careersPage: ''
      });
      setSnackbarMessage("Company created successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error creating company', error);
      setSnackbarMessage("Error creating company");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          Create New Company
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={company.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="size"
            label="Size"
            type='number'
            value={company.size}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="primary-industry-label">Primary Industry</InputLabel>
            <Select
              labelId="primary-industry-label"
              name="primaryIndustry"
              value={company.primaryIndustry}
              onChange={handleChange}
              label="Primary Industry"
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="secondary"
            label="Secondary"
            value={company.secondary}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="state"
            label="State"
            value={company.state}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="country"
            label="Country"
            value={company.country}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="careersPage"
            label="Careers Page URL"
            value={company.careersPage}
            onChange={handleChange}
            placeholder="www.careerspageurl.com"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Create Company
          </Button>
        </form>

        {response && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Company Created:
            </Typography>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </Box>
        )}

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
}

export default CreateCompany;